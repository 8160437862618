import { useEffect, useState } from "react";
import { httpClient } from "../../../util/Api";
import { auth, firebaseApp } from "../../../firebase";
import { uuid } from "uuidv4";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setInitUrl } from "../../../appRedux/actions";
import { isProd } from "../../../util/Env";
import axios from "axios";

export const useProvideAuth = () => {
  const dispatch = useDispatch();

  const [authUser, setAuthUser] = useState<object | null>(null);
  const [error, setError] = useState("");
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  auth.onAuthStateChanged(function (user) {
    setLoadingUser(false);
    if (user) {
      if (isProd()) {
        const webToken = localStorage.getItem("web-token");

        const newToken = webToken ?? uuid();
        localStorage.setItem("web-token", newToken);
        firebaseApp
          .firestore()
          .collection("NS_USER_PERMISSION")
          .doc(user?.uid)
          .set(
            {
              "meta.web-token": newToken,
            },
            { merge: true }
          );
      }
    }
    setAuthUser(user);
  });

  const userLogin = (
    user: { email: string; password: string },
    onError?: (error: any) => void
  ) => {
    
    axios.post(
      "https://europe-west1-algo-senti.cloudfunctions.net/corporate-login",
      {
        username: user.email,
        password: user.password,
        companyId: process.env.REACT_APP_CORPORATE_ID,
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    ).then((response) => {
     
      auth.signInWithCustomToken(response.data)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        setAuthUser(user);
        dispatch(setInitUrl("/index/home"));
        history.push("/index/home");
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
      });
    }).catch((error) => {
      onError && onError({message:error.response?.data??"Oturum açma başarısız!"});
    });

  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = () => {
    auth.signOut();
  };

  const getAuthUser = () => {
    return auth?.currentUser;
  };

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignOut,
    renderSocialMediaLogin,
  };
};
