export const PERMISSIONS = {
  LINES_INDEXES: "lines.indexes",
  LINES_XSEN030_P99: "lines.xsen030.p99",
  LINES_XSEN030_P10: "lines.xsen030.p10",
  LINES_XSEN030_P30: "lines.xsen030.p30",
  LINES_XSEN030_DAILY: "lines.xsen030.daily",
  LINES_XSEN070_P99: "lines.xsen070.p99",
  LINES_XSEN070_P10: "lines.xsen070.p10",
  LINES_XSEN070_P30: "lines.xsen070.p30",
  LINES_XSEN070_DAILY: "lines.xsen070.daily",
  LINES_XSEN500_P99: "lines.xsen500.p99",
  LINES_XSEN500_P10: "lines.xsen500.p10",
  LINES_XSEN500_P30: "lines.xsen500.p30",
  LINES_XSEN500_DAILY: "lines.xsen500.daily",
  LINES_XSEN500_LIMITED: "lines.xsen500.limited",
  LINES_WEEKLY: "weekly.lines",
  SIGNALS_XSEN030_P99: "signals.xsen030.p99",
  SIGNALS_XSEN030_P10: "signals.xsen030.p10",
  SIGNALS_XSEN030_P30: "signals.xsen030.p30",
  SIGNALS_XSEN030_DAILY: "signals.xsen030.daily",
  SIGNALS_XSEN070_P99: "signals.xsen070.p99",
  SIGNALS_XSEN070_P10: "signals.xsen070.p10",
  SIGNALS_XSEN070_P30: "signals.xsen070.p30",
  SIGNALS_XSEN070_DAILY: "signals.xsen070.daily",
  SIGNALS_XSEN500_P99: "signals.xsen500.p99",
  SIGNALS_XSEN500_P10: "signals.xsen500.p10",
  SIGNALS_XSEN500_P30: "signals.xsen500.p30",
  SIGNALS_XSEN500_DAILY: "signals.xsen500.daily",
  BIST_HOME_XSEN030: "bist-home.xsen030",
  BIST_HOME_XSEN070: "bist-home.xsen070",
  BIST_HOME_XSEN500: "bist-home.xsen500",
  TRADES_XSEN030: "trades.xsen030",
  TRADES_XSEN070: "trades.xsen070",
  TRADES_XSEN500: "trades.xsen500",
  SHORTTRADES_XSEN030: "shortTrades.xsen030",
  SHORTTRADES_XSEN070: "shortTrades.xsen070",
  WEB_ZEPHLEX_DAILY: "web-zephlex.daily",
  WEB_ZEPHLEX_MINUTE: "web-zephlex.minute",
  WEB_ZEPHLEX_SENTIMETER: "web-zephlex.sentimeter",
  WEB_ZEPHLEX_HOUR: "web-zephlex.hourly",
  WEB_ZEPHLEX_DASHBOARD: "web-zephlex.dashboard",
  WEB_ZEPHLEX_PREMIUM_MESSAGE: "web-zephlex.premium-message",
  BULLETIN_LIST: "bulletin.list",
  EXCHANGER: "exchanger.all",

  LINES_XHARZ: "lines.xharz",

  LINES_XSEN200_P10: "lines.xsen200.p10",
  LINES_XSEN200_P30: "lines.xsen200.p30",
  LINES_XSEN400_P10: "lines.xsen400.p10",
  LINES_XSEN400_P30: "lines.xsen400.p30",
  LINES_XSEN200_P99: "lines.xsen200.p99",
  LINES_XSEN400_P99: "lines.xsen400.p99",

  SIGNALS_XSEN200_P10: "signals.xsen200.p10",
  SIGNALS_XSEN200_P30: "signals.xsen200.p30",
  SIGNALS_XSEN400_P10: "signals.xsen400.p10",
  SIGNALS_XSEN400_P30: "signals.xsen400.p30",
  SIGNALS_XSEN200_P99: "signals.xsen200.p99",
  SIGNALS_XSEN400_P99: "signals.xsen400.p99",

  TRADES_XSEN200: "trades.xsen200",
  TRADES_XSEN400: "trades.xsen400",
};



export const isCooperateUser = (nsUser: any) => {
  if (!nsUser || !nsUser?.user) return false;
  if (nsUser?.cooperate) return true;
  return false;
};

export const hasPermission = (nsUser: any, permission: string) => {
  if (!nsUser || !nsUser?.user) return false;
  if (isCooperateUser(nsUser)) return true;
  return nsUser?.user?.[permission] === true;
};

export const hasOneOfPermissions = (nsUser: any, permissions: string[]) => {
  if (!nsUser || !nsUser?.user) return false;
  if (isCooperateUser(nsUser)) return true;
  return permissions.some((permission) => nsUser?.user?.[permission] === true);
};
