import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import ImkbEndeksSenetler from "../../../util/ImkbEndeksSenetler";
import klineInterval from "./params";

const COLLECTION_NAME = "NS_LINES_400_1W";

interface IXUData {
  lines400: IData[];
  requested: boolean;
  loaded: boolean;
  lastUpdate: number;

}

const initialState: IXUData = {
  lines400: [],
  requested: false,
  loaded: false,
  lastUpdate: 0,

};

export function loadLines400_1W_Snapshot(firebase: any, period: number, count: number) {
  if (period < 0 || ![0, 10, 30, 99].includes(period)) {
    console.log("Invalid period for line 400. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (new Date().getTime() - state.Lines400_1W_Snapshot.lastUpdate < 60 * 1000) return;
    dispatch({
      type: SENTIMENT_ACTION.LINES_400_1W_SNAPSHOT,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}_P${period}`)
      .orderBy("dateTime", "desc")
      .limit(count)
      .get().then((querySnapshot: any) => {
        var lines400_1W_Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          //if (lines400Data.some((x: any) => { x.Symbol == doc.data().symbol })) return;

          lines400_1W_Data.push({
            name: "lines400",
            Index: doc.data().index,
            Symbol: doc.data().symbol,
            Sentiment: doc.data().sentiment,
            Order: doc.data().order,
            Group: doc.data().group,
            ODate: doc.data().openDate,
            OPrice: doc.data().openPrice,
            Price: doc.data().price,
            DailyChange: doc.data().dailyChange,
            Vbts: doc.data().vbts,
            First5to10: doc.data().first5to10,
            IsNewFirst5: doc.data().isNewFirst5,
            Viop: doc.data().viop,
            PerDay: doc.data().perDay,
            SentimentList: doc.data().sentimentList,
            PriceList: doc.data().priceList,
            ChangeList: doc.data().changeList,
            ReChangeList: doc.data().changeList.slice().reverse(),
            dateTime: doc.data().dateTime.toDate(),
            Time: doc.data().time ?? 0,
            UpdateTime: moment
              .unix(doc.data().dateTime.seconds)
              .format("DD.MM.yy HH:mm"),
            LineDate: doc.data().dateTime.seconds,
            Indexes: ImkbEndeksSenetler.filter(x => x.name == doc.data().symbol)[0]?.val
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.LINES_400_1W_SNAPSHOT,
          payload: {
            lines400: lines400_1W_Data.sort((a: any, b: any) => { return a.Order - b.Order }),
            loaded: true,
            requested: true,
            lastUpdate: new Date().getTime()
          },
        });
      });
  };
}

export default function Lines400_1W_Snapshot(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.LINES_400_1W_SNAPSHOT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const lines400_1W_SnapshotSelector = (state: any) => state.Lines400_1W_Snapshot;
